@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);
body {
  font-family: 'Poppins', sans-serif;
}
.post img {
  display: unset;
}
.object-fit-cover {
  object-fit: cover;
}
